var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"referential metrics-container"},[_c('h1',{staticClass:"metrics-super-title"},[_vm._v(" Chiffres clés "),_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.exportAllAsCSV()}}},[_vm._v("Tout exporter en csv")])],1),_c('div',{staticClass:"metrics-container"},[_c('h2',{staticClass:"metrics-title is-primary"},[_vm._v(" Nombre d'utilisateurs actifs (au moins une sortie dans l'année) "),_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.exportAsCSV(
              'utilisateurs',
              _vm.activeUsersColumns,
              _vm.metrics.activeUsersPerYear
            )}}},[_vm._v("Exporter en csv")])],1),_c('b-table',{attrs:{"data":_vm.metrics.activeUsersPerYear,"columns":_vm.activeUsersColumns,"default-sort":['lac', 'asc']}}),_c('h2',{staticClass:"metrics-title"},[_vm._v(" Nombre d'inscriptions par an "),_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.exportAsCSV(
              'inscriptions',
              _vm.userRegistrationsColumns,
              _vm.metrics.userRegistrationsPerYear
            )}}},[_vm._v("Exporter en csv")])],1),_c('b-table',{attrs:{"data":_vm.metrics.userRegistrationsPerYear,"columns":_vm.userRegistrationsColumns,"default-sort":['annee', 'asc']}}),_c('h2',{staticClass:"metrics-title"},[_vm._v(" Nombre de sorties par lac et par an "),_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.exportAsCSV('sorties', _vm.tripsPerLakeColumns, _vm.metrics.tripsPerLake)}}},[_vm._v("Exporter en csv")])],1),_c('b-table',{attrs:{"data":_vm.metrics.tripsPerLake,"columns":_vm.tripsPerLakeColumns,"default-sort":['lac', 'asc']}}),_c('h2',{staticClass:"metrics-title"},[_vm._v(" Nombre de captures par lac et par an "),_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.exportAsCSV(
              'captures',
              _vm.catchesPerLakeColumns,
              _vm.metrics.catchesPerLake
            )}}},[_vm._v("Exporter en csv")])],1),_c('b-table',{attrs:{"data":_vm.metrics.catchesPerLake,"columns":_vm.catchesPerLakeColumns,"default-sort":['lac', 'asc']}}),_c('h2',{staticClass:"metrics-title"},[_vm._v(" Nombre de mesures automatiques par lac et par an "),_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.exportAsCSV(
              'mesures',
              _vm.automaticMeasuresPerLakeColumns,
              _vm.metrics.automaticMeasuresPerLake
            )}}},[_vm._v("Exporter en csv")])],1),_c('b-table',{attrs:{"data":_vm.metrics.automaticMeasuresPerLake,"columns":_vm.automaticMeasuresPerLakeColumns,"default-sort":['lac', 'asc']}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }