


































































































































import BackendService from "@/services/BackendService";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Metrics extends Vue {
  url = "/v1/metrics";
  metrics = {
    activeUsersPerYear: [],
    userRegistrationsPerYear: [],
    tripsPerLake: [],
    catchesPerLake: [],
    automaticMeasuresPerLake: []
  };
  activeUsersColumns = [
    { field: "annee", label: "Année", sortable: true },
    { field: "lac", label: "Lac", sortable: true },
    { field: "total", label: "Utilisateurs actifs", sortable: true }
  ];
  userRegistrationsColumns = [
    { field: "annee", label: "Année", sortable: true },
    { field: "total", label: "Inscriptions", sortable: true }
  ];
  tripsPerLakeColumns = [
    { field: "annee", label: "Année", sortable: true },
    { field: "lac", label: "Lac", sortable: true },
    { field: "total", label: "Nombre de sorties", sortable: true }
  ];
  catchesPerLakeColumns = [
    { field: "annee", label: "Année", sortable: true },
    { field: "lac", label: "Lac", sortable: true },
    { field: "total", label: "Nombres de prises", sortable: true }
  ];
  automaticMeasuresPerLakeColumns = [
    { field: "annee", label: "Année", sortable: true },
    { field: "lac", label: "Lac", sortable: true },
    { field: "total", label: "Mesures automatiques", sortable: true }
  ];

  created() {
    BackendService.backendGet(this.url).then(res => {
      this.metrics = res;
    });
  }

  exportAllAsCSV() {
    let csvContent = "";

    const columns = [
      { field: "annee", label: "Année", sortable: true },
      { field: "lac", label: "Lac", sortable: true },
      { field: "indic_type", label: "Indicateur", sortable: true },
      { field: "total", label: "Valeur", sortable: true }
    ];
    for (var i = 0; i < columns.length; i++) {
      if (i > 0) {
        csvContent += ";";
      }
      csvContent += ((columns[i] as unknown) as Column).label;
    }
    csvContent += this.getCSVRows(
      columns,
      this.metrics.activeUsersPerYear,
      "Nombre d'utilisateurs actifs (au moins une sortie dans l'année)"
    );
    csvContent += this.getCSVRows(
      columns,
      this.metrics.userRegistrationsPerYear,
      "Nombre d'inscriptions par an"
    );
    csvContent += this.getCSVRows(
      columns,
      this.metrics.tripsPerLake,
      "Nombre de sorties par lac et par an"
    );
    csvContent += this.getCSVRows(
      columns,
      this.metrics.catchesPerLake,
      "Nombre de captures par lac et par an "
    );
    csvContent += this.getCSVRows(
      columns,
      this.metrics.automaticMeasuresPerLake,
      "Nombre de mesures automatiques par lac et par an"
    );
    this.downloadCSV("ensemble_indicateurs", csvContent);
  }

  exportAsCSV(fileName: string, columns: Array<string>, array: Array<any>) {
    let csvContent = "";
    for (var i = 0; i < columns.length; i++) {
      if (i > 0) {
        csvContent += ";";
      }
      csvContent += ((columns[i] as unknown) as Column).label;
    }
    csvContent += "\n";
    // Add content rows
    csvContent += array
      .map(row => {
        var csvRow = "";
        for (var i = 0; i < columns.length; i++) {
          if (i > 0) {
            csvRow += ";";
          }
          csvRow += row[((columns[i] as unknown) as Column).field];
        }
        return csvRow;
      })
      .join("\n");
    this.downloadCSV(fileName, csvContent);
  }

  getCSVRows(columns: Array<Column>, array: any, prefixLine: string) {
    let csvContent = "\n";
    csvContent += (array as Array<any>)
      .map(row => {
        var csvRow = "";
        for (var i = 0; i < columns.length; i++) {
          if (i > 0) {
            csvRow += ";";
          }
          const columnName = ((columns[i] as unknown) as Column).field;
          if (columnName == "indic_type") {
            csvRow += prefixLine;
          } else {
            csvRow += row[columnName];
          }
        }
        return csvRow;
      })
      .join("\n");
    return csvContent;
  }

  downloadCSV(fileName: string, csvContent: string) {
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
    hiddenElement.target = "_blank";

    //provide the name for the CSV file to be downloaded
    const d = new Date();
    var mm = d.getMonth() + 1;
    var dd = d.getDate();
    let dateString =
      d.getFullYear() +
      "-" +
      (mm > 9 ? "" : "0") +
      mm +
      "-" +
      (dd > 9 ? "" : "0") +
      dd;
    hiddenElement.download = "Fishola_" + fileName + "_" + dateString + ".csv";
    hiddenElement.click();
  }
}

class Column {
  field: string;
  label: string;
}
