























































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import BackendService from "@/services/BackendService";
import UtilityServices from "@/services/UtilityServices";
import Constants from "@/services/Constants";

@Component({
  components: {}
})
export default class CatchEditionPage extends Vue {
  @Prop() catchId: string;
  aCatch: any = {};
  trip: any = {};
  speciesIdMap = new Map<string, string>();
  techniquesIdMap = new Map<string, string>();
  lakesIdMap = new Map<string, string>();
  speciesNamesMap = new Map<string, string>();
  sortedSpeciesNames: Array<string> = [];
  measurementPicURL = "";
  otherPicsUrls: Array<string> = [];

  mounted(): void {
    this.loadCatch();
  }

  async loadCatch() {
    if (this.speciesIdMap.size == 0) {
      let species = await BackendService.backendGet(
        "/v1/referential/raw-species"
      );
      species.forEach((specie: { id: string; name: string }) => {
        this.speciesIdMap.set(specie.id, specie.name);
        this.speciesNamesMap.set(specie.name, specie.id);
        this.sortedSpeciesNames.push(specie.name);
      });
      this.sortedSpeciesNames = this.sortedSpeciesNames.sort();

      let techniques = await BackendService.backendGet(
        "/v1/referential/techniques"
      );
      techniques.forEach((technique: { id: string; name: string }) => {
        this.techniquesIdMap.set(technique.id, technique.name);
      });
      let lakes = await BackendService.backendGet("/v1/referential/lakes");
      lakes.forEach((lake: { id: string; name: string }) => {
        this.lakesIdMap.set(lake.id, lake.name);
      });
    }
    this.measurementPicURL = "";
    this.otherPicsUrls = [];
    this.trip = await BackendService.backendGet(
      "/v1/trips/catches/" + this.catchId
    );
    this.aCatch = this.trip.catchs.find((c: any) => c.id == this.catchId);
    if (this.aCatch.hasMeasurementPicture) {
      this.measurementPicURL = Constants.apiUrl(
        `/v1/pictures/measure/${this.aCatch.id}/preview`
      );
    }
    this.aCatch.pictureOrders.forEach((picOrder: string) => {
      const otherPicURL = Constants.apiUrl(
        `/v1/pictures/${this.catchId}/${picOrder}`
      );
      this.otherPicsUrls.push(otherPicURL);
    });
    this.aCatch.sizeInMm = this.aCatch.size * 10;
  }

  async save() {
    let url = "/v1/trips/catches/" + this.catchId;
    try {
      await BackendService.backendPut(url, this.aCatch);
      this.$emit("referential-updated");
      this.$buefy.toast.open({
        message: "Informations modifiée avec succès.",
        type: "is-success"
      });
      this.loadCatch();
    } catch (e) {
      console.error(e);
      this.$buefy.toast.open({
        message:
          "Erreur lors de la modification de la prise. Veuillez vérifier vos modifications.",
        type: "is-danger"
      });
    }
  }

  cancel() {
    this.$router.go(-1);
  }

  formatDate(date: number[]): string {
    return UtilityServices.formatDate(date);
  }
}
