


































import Menu from "@/components/Menu.vue";
import "@mdi/font/css/materialdesignicons.css";

export default {
  name: "App",
  components: {
    Menu
  }
};
