





















































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import BackendService from "@/services/BackendService";

import ReferentialItem from "@/components/ReferentialItem.vue";
import UtilityServices from "@/services/UtilityServices";

@Component({
  components: {
    ReferentialItem
  }
})
export default class Refenretial extends Vue {
  @Prop() name!: string;
  @Prop() url!: string;
  @Prop() columns!: any[];
  @Prop() data: any[] = [];
  @Prop({ default: true }) editable: boolean;
  @Prop({ default: ["id", "desc"] }) defaultSort: string[];
  @Prop() nextPlannifiedDate: number[];
  selection = { item: null };

  /* The function used to create new elements. If not specified, create button will not be displayed */
  @Prop({ default: null }) createElement: () => any;
  /* Indicates whether user is allowed to deleted elements in the table. */
  @Prop({ default: false }) canDelete: boolean;
  /** The function used to determine if a given element can be deleted.
   * If not specified, only the "canDelete" boolean wil be used to determine if deletion is allowed.
   * */
  @Prop({ default: null }) canDeletePredicate: (
    elemenToDelete: any
  ) => Promise<boolean>;
  // Cached value of all elements for which deletion is allowed
  allowedDeletionElements: any[] = [];

  mounted() {
    this.loadData();
  }

  @Watch("nextPlannifiedDate")
  nextPlannifiedDateChanged(): void {
    this.loadData();
  }

  formatDate(puet: number[]): string {
    return UtilityServices.formatDate(puet);
  }

  loadData() {
    while (this.data && this.data.length) {
      this.data.pop();
    }
    this.allowedDeletionElements = [];
    BackendService.backendGet(this.url).then(res => {
      this.data = res;
      this.$emit("elementsLoaded", this.data);
      this.checkCanDeletePredicate();
    });
  }

  showCreateDialog() {
    let newElement = this.createElement();
    // This will trigger modal appearance
    this.selection.item = newElement;
  }

  sendNotification(target: any, event: Event) {
    event.stopPropagation();
    this.$emit("send-notification", target);
  }

  /**
   * If required by configuration, ask to server if delete is allowed.
   */
  checkCanDeletePredicate() {
    if (this.canDelete && this.data) {
      this.data.forEach(element => {
        // Call predicate for each element
        if (this.canDeletePredicate != null) {
          this.canDeletePredicate(element).then(allowDeletion => {
            if (allowDeletion && this.allowedDeletionElements != null) {
              this.allowedDeletionElements.push(element["id"]);
            }
          });
        } else {
          if (this.allowedDeletionElements != null) {
            this.allowedDeletionElements.push(element["id"]);
          }
        }
      });
    }
  }

  showLink(event: Event, url: string) {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation();

    window.open(url, "_blank");
  }

  showDeleteDialog(event: Event, element: any) {
    // Do not foward click event to row (would trigger modal)
    event.stopPropagation();

    if (
      this.allowedDeletionElements &&
      this.allowedDeletionElements.includes(element["id"])
    ) {
      // Ask for confirmation
      this.$buefy.dialog.confirm({
        title: "Suppression",
        message:
          "Êtes-vous sûr de vouloir supprimer " +
          (element["name"] || "cet élément") +
          " ?",
        confirmText: "Supprimer",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          // Sends an HTTP DELETE request at url/id
          BackendService.backendDelete(`${this.url}/${element["id"]}`).then(
            res => {
              this.$buefy.toast.open({
                message: (element["name"] || "Élément") + " supprimé",
                type: "is-success"
              });
              this.loadData();
            },
            error => {
              this.$buefy.toast.open({
                message:
                  "Erreur lors de la supression de " +
                  (element["name"] || "l'élément") +
                  " : " +
                  error.message,
                type: "is-danger"
              });
            }
          );
        }
      });
    } else {
      // Explain why we cannot delete
      this.$buefy.dialog.alert(
        "Impossible de supprimer cet élément car il est référencé ailleurs au sein de l'application"
      );
    }
  }
}
