

































import { Component, Prop, Vue } from "vue-property-decorator";
import BackendService from "@/services/BackendService";
import Constants from "../../../fishola-mobile/src/services/Constants";

@Component
export default class ImageUploader extends Vue {
  @Prop() itemId: string;
  @Prop() isMiniature: boolean;

  async uploadImageFile(e: InputEvent): Promise<void> {
    const uploadedFile = this.$refs.upload as HTMLInputElement;
    if (uploadedFile.files) {
      const file = uploadedFile.files[0];
      const base64 = await this.getBase64(file);
      const id = this.itemId ? this.itemId : "temp-id";
      let url = "/v1/news-picture/";
      if (this.isMiniature) {
        url = "/v1/news-miniature/";
      }
      const newsPicture = await BackendService.backendPost(url + id, base64);
      const newsPicturesURL = Constants.apiUrl(
        "/v1/news-picture/" + newsPicture["id"]
      );
      this.$emit("uploaded-pic", newsPicturesURL);
    }
  }

  getBase64(file: any): Promise<string> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        resolve(reader.result);
      };
      reader.onerror = function(error) {
        reject(error);
      };
    });
  }
}
