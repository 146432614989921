







































































































import ReferentialItem from "@/components/ReferentialItem.vue";

import { Component, Vue, Watch } from "vue-property-decorator";
import BackendService from "@/services/BackendService";
import UtilityServices from "@/services/UtilityServices";
@Component({
  components: {
    ReferentialItem
  }
})
export default class LakesVue extends Vue {
  page = 1;
  total = 0;
  loading = false;
  lastTimerId: number;
  catches = [];
  filters: any = {};
  sortField = "size";
  sortOrder = "desc";
  columns: any[] = [
    {
      field: "catchId",
      label: "Identifiant",
      searchable: true,
      sortable: true
    },
    {
      field: "nomDeLaPlateforme",
      label: "Plateforme",
      searchable: true,
      sortable: true
    },
    {
      field: "dateDeLaSortie",
      label: "Date Sortie",
      searchable: true,
      sortable: true
    },
    {
      field: "typeDePeche",
      label: "Type de pêche",
      searchable: true,
      sortable: true
    },
    {
      field: "especeCapturee",
      label: "Espèce Capturée",
      searchable: true,
      sortable: true
    },
    {
      field: "poidsDuPoisson",
      label: "Poids du poisson",
      searchable: true,
      sortable: true
    },
    {
      field: "longueurTotaleDuPoisson",
      label: "Taille du poisson",
      searchable: true,
      sortable: true
    },
    {
      field: "longueurTotaleDuPoissonCalculee",
      label: "Mesure automatique",
      searchable: true,
      sortable: true
    },
    {
      field: "aExclure",
      label: "Exclure de l'export",
      searchable: true,
      sortable: true
    }
  ];

  mounted() {
    this.onSort("date_de_la_sortie", "desc");
  }

  async loadData() {
    if (!this.loading) {
      this.loading = true;
      while (this.catches && this.catches.length) {
        this.catches.pop();
      }
      try {
        // Load catches with current pagination, sort and filters
        let url =
          "/v1/trips/export/" +
          (this.page - 1) +
          "/" +
          this.sortField +
          "/" +
          this.sortOrder;
        url += this.computeFiltersQueryParameters(this.filters);
        let res = await BackendService.backendGet(url);
        this.catches = res.elements;
        this.total = res.total;
      } catch (e) {
        console.error(e);
      }

      this.loading = false;
    }
  }

  formatDate(puet: number[]): string {
    return UtilityServices.formatDate(puet);
  }

  onPageChange(page: number) {
    this.page = page;
    this.loadData();
  }

  onSort(field: string, order: string) {
    this.sortField = UtilityServices.camelCaseToUnderscore(field);
    this.sortOrder = order;
    this.page = 1;
    this.loadData();
  }

  onFiltersChange(filters: any) {
    this.filters = filters;
    this.page = 1;
    this.loadDataDebounced();
  }

  /**
   * Waits 500ms calling loadData. If during this delay another call is made, cancels the first call and schedules the second.
   */
  loadDataDebounced() {
    clearTimeout(this.lastTimerId);
    this.lastTimerId = setTimeout(this.loadData, 450);
  }

  /**
   * Returns a query parameters url corresponding to the given filter object
   * e.g. {
   *   'weight': '42',
   *   'speciesId': 'Perche'
   * }
   * will return '?weight=42&speciesId='
   */
  computeFiltersQueryParameters(filterObject: any) {
    let url = "";
    let firstKey = true;
    Object.keys(filterObject).forEach(filter => {
      let filteredValue = filterObject[filter].trim();
      if (filteredValue) {
        if (firstKey) {
          url += "?";
          firstKey = false;
        } else {
          url += "&";
        }
        url +=
          UtilityServices.camelCaseToUnderscore(filter) + "=" + filteredValue;
      }
    });
    return url;
  }

  async exportCsv() {
    let csvContent = await BackendService.backendGetRaw("/v1/trips/export");
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
    hiddenElement.target = "_blank";
    const d = new Date();
    var mm = d.getMonth() + 1;
    var dd = d.getDate();
    let dateString =
      d.getFullYear() +
      "-" +
      (mm > 9 ? "" : "0") +
      mm +
      "-" +
      (dd > 9 ? "" : "0") +
      dd;
    hiddenElement.download = "Fishola_Export_" + "_" + dateString + ".csv";
    hiddenElement.click();
  }

  rowClicked(row: any) {
    this.$router.push("/catch/" + row.catchId);
  }
}
