



































import Referential from "@/components/Referential.vue";

import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    Referential
  }
})
export default class UsersVue extends Vue {
  userEmails = "";
  userColumns: any[] = [
    {
      field: "id",
      label: "Identifiant",
      visible: false,
      readOnly: true
    },
    {
      field: "firstName",
      label: "Prénom",
      readOnly: true
    },
    {
      field: "lastName",
      label: "Nom",
      readOnly: true
    },
    {
      field: "email",
      label: "E-mail",
      readOnly: true
    },
    {
      field: "gender",
      label: "Genre",
      readOnly: true
    },
    {
      field: "birthYear",
      label: "Année de naissance",
      readOnly: true
    },
    {
      field: "excludeFromExports",
      label: "Exclu des exports",
      isABoolean: true
    },
    {
      field: "createdOn",
      label: "Date de création",
      isADate: true,
      readOnly: true
    }
  ];

  usersLoaded(zeUsers: any[]) {
    this.userEmails = zeUsers
      .filter(u => u.acceptsEmailNotifications)
      .map(u => u.email)
      .join(";");
  }

  copyMails() {
    navigator.clipboard.writeText(this.userEmails);
    const nbUsers = this.userEmails.split(";").length;
    this.$buefy.toast.open({
      message:
        "Les emails des " +
        nbUsers +
        " utilisateurs acceptant d'être contactés par mails ont été copiés dans votre presse-papier. Vous pouvez les coller directement dans le champ 'destinataire' de votre email.",
      type: "is-success",
      duration: 7000
    });
  }
}
