




















































































import { Component, Prop, Vue } from "vue-property-decorator";

import router from "@/router";

import BackendService from "@/services/BackendService";

@Component
export default class Menu extends Vue {
  mounted() {
    BackendService.backendGet("/v1/security/admin-check").then(
      () => {
        // Rien à faire
      },
      error => {
        this.$buefy.toast.open({
          message: "Vous n'êtes plus connecté\u00B7e",
          type: "is-danger"
        });
        router.push("/login");
      }
    );
  }
  doLogout() {
    BackendService.backendPost("/v1/security/admin-logout").then(() => {
      router.push("/login");
    });
  }
}
