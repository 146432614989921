









































import router from "@/router";

import BackendService from "@/services/BackendService";

import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class LoginView extends Vue {
  password = "";
  errorMessage = "";

  constructor() {
    super();
  }

  doLogin() {
    this.errorMessage = "";
    BackendService.backendPost("/v1/security/admin-login", {
      password: this.password
    }).then(
      () => {
        router.push("home");
      },
      err => {
        if (err.status == 401) {
          this.errorMessage = "Mot de passe incorrect";
        } else {
          this.errorMessage = err;
        }
      }
    );
  }
}
