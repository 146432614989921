











































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import BackendService from "@/services/BackendService";

import { Editor, EditorContent } from "@tiptap/vue-2";
import Image from "@tiptap/extension-image";
import StarterKit from "@tiptap/starter-kit";

import { LocalDateTime, nativeJs } from "@js-joda/core";
import ImageUploader from "./ImageUploader.vue";

@Component({
  components: { EditorContent, ImageUploader }
})
export default class RefenretialItem extends Vue {
  @Prop() item!: any;
  @Prop() columns!: any[];
  @Prop() backendUrl!: string;
  input = { file: null };
  picture = { file: null };
  miniaturePicURL = "";
  editor = new Editor({
    content: "",
    extensions: [StarterKit, Image]
  });
  dateRange: Date[] = [];

  mounted(): void {
    this.itemChanged();
  }

  @Watch("item")
  itemChanged(): void {
    // Search for an html column (only one per item permited)
    let htmlColumns = this.columns.filter(c => c.isHTML);
    if (htmlColumns.length > 0) {
      let htmlContent = this.item[htmlColumns[0].field];
      // Update editor accordingly
      if (htmlContent) {
        this.editor.commands.setContent(htmlContent, false);
      }
    }

    // Initialize dateRange
    let rangeBeginningColumn = this.columns.filter(c => c.isAPeriodBeginning);
    let rangeEndColumn = this.columns.filter(c => c.isAPeriodEnd);
    if (rangeBeginningColumn.length && rangeEndColumn.length) {
      this.dateRange = [];
      this.dateRange.push(
        this.parseLocalDateTime(this.item[rangeBeginningColumn[0].field])
      );
      this.dateRange.push(
        this.parseLocalDateTime(this.item[rangeEndColumn[0].field])
      );
    }
  }

  beforeDestroy() {
    this.editor?.destroy();
  }

  uploadedPic(url: string): void {
    console.error(url);
    this.editor
      .chain()
      .focus()
      .setImage({ src: url })
      .run();
  }

  uploadedMiniature(url: string): void {
    this.item["miniatureURL"] = url;
    const splitted = url.split("/");
    this.item["miniatureId"] = splitted[splitted.length - 1];
    this.$forceUpdate();
  }

  save(closeModal: () => void) {
    // Search for an html column (only one per item permited)
    let htmlColumns = this.columns.filter(c => c.isHTML);
    if (htmlColumns.length > 0) {
      // Get editor value
      this.item[htmlColumns[0].field] = this.editor.getHTML();
    }

    // Translate dateRange into beggining/end dates
    let rangeBeginningColumn = this.columns.filter(c => c.isAPeriodBeginning);
    let rangeEndColumn = this.columns.filter(c => c.isAPeriodEnd);

    if (this.dateRange && this.dateRange[0] && this.dateRange[1]) {
      this.dateRange[0].setHours(7);
      this.dateRange[0].setMinutes(30);
      this.item[rangeBeginningColumn[0].field] = LocalDateTime.from(
        nativeJs(this.dateRange[0])
      );

      this.dateRange[1].setHours(21);
      this.dateRange[1].setMinutes(0);
      this.item[rangeEndColumn[0].field] = LocalDateTime.from(
        nativeJs(this.dateRange[1])
      );
    }
    let onSavedCallback = () => {
      this.$emit("referential-updated");
      this.input.file = null;
      this.picture.file = null;
      this.$buefy.toast.open({
        message: "Modifications enregistrées",
        type: "is-success"
      });
      if (closeModal) {
        closeModal();
      }
    };
    // Convert file in base64 (if required)
    if (this.input.file) {
      this.getBase64(this.input.file).then(
        base64 => {
          this.item["base64Content"] = base64;
          this.doSave(onSavedCallback);
        },
        err => {
          this.$buefy.toast.open({
            message: "Erreur lors de la lecture du fichier.",
            type: "is-danger"
          });
        }
      );
    } else if (this.picture.file) {
      this.getBase64(this.picture.file).then(
        base64 => {
          this.item["miniaturePic"] = base64;
          this.doSave(onSavedCallback);
        },
        err => {
          this.$buefy.toast.open({
            message: "Erreur lors de la lecture du fichier.",
            type: "is-danger"
          });
        }
      );
    } else {
      this.doSave(onSavedCallback);
    }
  }

  doSave(onSavedCallback: () => void) {
    if (this.item.id) {
      // Update : PUT
      let url = this.backendUrl + "/" + this.item.id;
      BackendService.backendPut(url, this.item).then(onSavedCallback, err => {
        this.input.file = null;
        this.picture.file = null;
        this.$buefy.toast.open({
          message:
            "Erreur lors de la modification de " +
            this.item["name"] +
            ". Veuillez vérifier vos modifications.",
          type: "is-danger"
        });
      });
    } else {
      // Create : POST
      let url = this.backendUrl;
      BackendService.backendPost(url, this.item).then(onSavedCallback, err => {
        this.input.file = null;
        this.picture.file = null;
        this.$buefy.toast.open({
          message:
            "Erreur lors de la création. Veuillez vérifier qu'un élément avec ce nom n'existe pas déjà.",
          type: "is-danger"
        });
      });
    }
  }

  parseLocalDateTime(someLocalDateTime: number[]): Date {
    if (someLocalDateTime[5]) {
      return new Date(
        someLocalDateTime[0],
        someLocalDateTime[1] - 1,
        someLocalDateTime[2],
        someLocalDateTime[3],
        someLocalDateTime[4],
        someLocalDateTime[5]
      );
    } else {
      return new Date(
        someLocalDateTime[0],
        someLocalDateTime[1] - 1,
        someLocalDateTime[2],
        someLocalDateTime[3],
        someLocalDateTime[4]
      );
    }
  }

  formatDate(puet: any): string {
    let theDate: Date = this.parseLocalDateTime(puet);
    var hourOptions: Intl.DateTimeFormatOptions = {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric"
    };
    let result: string = theDate.toLocaleTimeString("fr-FR", hourOptions);
    return result;
  }

  getBase64(file: any): Promise<string> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        resolve(reader.result);
      };
      reader.onerror = function(error) {
        reject(error);
      };
    });
  }

  onSaved(closeModal: () => void) {
    this.$emit("referential-updated");
    this.input.file = null;
    this.picture.file = null;
    if (closeModal) {
      closeModal();
    }
  }
}
