

































import Referential from "@/components/Referential.vue";
import BackendService from "@/services/BackendService";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    Referential
  }
})
export default class TechniquesVue extends Vue {
  url = "/v1/referential/techniques";
  techniqueColumns: any[] = [
    {
      field: "id",
      label: "Identifiant",
      visible: false,
      readOnly: true
    },
    {
      field: "name",
      label: "Nom"
    },
    {
      field: "exportAs",
      label: "Nom d'export"
    }
  ];

  createTechnique(): any {
    return {
      name: "Nouvelle technique",
      builtIn: true,
      exportAs: "technique"
    };
  }

  canDeleteTechnique(technique: any): Promise<boolean> {
    return BackendService.backendGet(
      this.url + "/can-delete/" + technique["id"]
    );
  }
}
