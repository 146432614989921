
























import BackendService from "@/services/BackendService";

import router from "@/router";

import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class DispatcherView extends Vue {
  constructor() {
    super();
  }

  mounted() {
    this.checkForActiveSession();
  }

  checkForActiveSession() {
    BackendService.backendGet("/v1/security/admin-check").then(
      () => {
        router.push("/home");
      },
      error => {
        router.push("/login");
      }
    );
  }
}
